import styled from 'styled-components';
import React from 'react';
import {PageContent} from '../components/Layout';
import {graphql, useStaticQuery} from 'gatsby';
import {Strapi_ComponentMoleculesSocialLink, Strapi_ComponentMoleculesContact, Strapi_Contact} from '../../typegen/graphql';
import {Helmet} from "react-helmet"
import {useLocation} from '@reach/router';
import theme from '../styles/theme';
import {devices} from '../styles/breakpoints';
import {Appear, PageContentContainer} from '../components/atoms';
import {NavigationContext} from '../services/navigation';
import {StaticImage} from 'gatsby-plugin-image';

type Platform = 'facebook' | 'imdb' | 'instagram' | 'spotify' | 'youtube';

const Container = styled(PageContent)`
  display: flex;
  @media ${devices.tabletPortrait} {
    /* align-items: center; */
  }
  `;

// let myAdd: (baseValue: number, increment: number) => number = function (
  const ContactContainer = styled(PageContentContainer)`
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  @media ${devices.tabletPortrait} {
    padding: 0;
  }
`;

const LinksContainer = styled.div`
  /* width: 80%; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 80vw;
    width: 300px;
    height: 2px;
    top: -32px;
    left: calc(50% - 150px);
    background-color: ${theme.palette.primary};
    opacity: 0.2;
  }
  @media ${devices.tabletPortrait} {
    gap: 32px;
    /* a32ign-items: center; */
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 400px;
      height: 2px;
      top: -32px;
      left: calc(50% - 200px);
      background-color: ${theme.palette.primary};
      opacity: 0.2;
    }
  }
`;

const IconLink = styled.a`
  width: 32px;
  height: 32px;
  @media ${devices.tabletPortrait} {
    width: 40px;
    height: 40px;
  }
  @media ${devices.desktop} {
    width: 48px;
    height: 48px;
  }
  cursor: pointer;
  display: inline-block;
  & > div {
    transition: transform 200ms ease-out;
    transform: scale(1);
    opacity: 0.8;
  }
  &:hover {
    & > div {
      transform: scale(1.1);
      opacity: 1;
    }
  }
`;

const Mails = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 48px;
  margin-bottom: 90px;
  @media ${devices.tabletLandscape} {
    grid-row-gap: 40px;
    grid-column-gap: 90px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MailContainer = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
`;

const Protasi = styled.div`
  margin-bottom: 24px;
  color: white;
  text-align: center;
  font-size: 16px;
  @media ${devices.tabletPortrait} {
    margin-bottom: 48px;
    font-size: 18px;
  }
  @media ${devices.desktop} {
    margin-bottom: 64px;
  }
`;

const MailDescription = styled.p`
  margin: 0;
  color: white;
  text-transform: capitalize;
  font-size: 16px;
  @media ${devices.tabletPortrait} {
    font-size: 18px;
  }
  `;

const Mail = styled.a`
  color: ${theme.palette.primary}90;
  position: relative;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  transition: transform 200ms ease-out;
  font-size: 16px;
  @media ${devices.tabletPortrait} {
    font-size: 18px;
  }
  &::after {
    content: '';
    pointer-events: none;
    position: absolute;
    width: 50%;
    transform: scaleX(0.7);
    transform-origin: left;
    height: 2px;
    bottom: -6px;
    left: -10%;
    background-color: ${theme.palette.primary};
    opacity: 0.1;
    transition: all 300ms ease-out;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
      opacity: 0.5;
      bottom: -6px;
      left: 0;
    }
    /* color: ${theme.palette.secondary}; */

  }
`;

const renderIcon: (link: Strapi_ComponentMoleculesSocialLink) => any = link => {
  switch(link.platform) {
    case 'facebook': return <StaticImage placeholder="none" src='../images/facebook_white.png' alt="facebook"/>
    case 'imdb': return <StaticImage placeholder="none" src='../images/imdb_white.png' alt="imdb"/>
    case 'instagram': return <StaticImage placeholder="none" src='../images/insta_white.png' alt="insta"/>
    case 'spotify': return <StaticImage placeholder="none" src='../images/spotify_white.png' alt="spotify"/>
    case 'youtube': return <StaticImage placeholder="none" src='../images/youtube_white.png' alt="youtube"/>
  }
};

const Contact: React.FC = () => {
  const {setLocation} = React.useContext(NavigationContext);
  React.useEffect(() => {
    setLocation(window.location.pathname)
  }, [])
  const ref = React.useRef();
  const {state} = useLocation();
  const {strapi, bisbikis} = useStaticQuery(graphql`
    query {
      bisbikis: file(relativePath: {eq: "mesaia4.jpg"}) {
        publicURL
      }
      strapi {
        contact {
          emails {
            id
            description
            email
          }
          links {
            platform
            url
          }
        }
      }
    }
  `);
  const {links, emails} = strapi.contact as Strapi_Contact;
  return (
    <Container ref={ref}>
      <Helmet
        title={`Contact | Stavros Markonis`}
        meta={[
          {
            name: `description`,
            content: 'Page to contact Stavros Markonis through email or social media',
          },
          {
            property: `og:title`,
            content: `Contact | Stavros Markonis`,
          },
          {
            name: 'keywords',
            content: 'contact, Stavros Markonis, email, social media, public relations, cinemble',
          },
          {
            property: `og:description`,
            content: 'Page to contact Stavros Markonis through email or social media',
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${process.env.GATSBY_CLIENT_ORIGIN}${bisbikis?.publicURL}`
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: `Contact | Stavros Markonis`,
          },
          {
            name: `twitter:description`,
            content: 'Page to contact Stavros Markonis through email or social media',
          },
        ]}
      />
      <Appear>
        <ContactContainer translation={(state as any)?.enableTranslationTransition}>
          <Protasi>If you would like to contact Stavros, don't hesitate to reach out:</Protasi>
          <Mails>
            {(emails as Strapi_ComponentMoleculesContact[]).map(email => (
              <MailContainer key={email.id}>
                <MailDescription>{email?.description}</MailDescription>
                <Mail href={`mailto:${email?.email}`}>
                  {email?.email}
                </Mail>
              </MailContainer>
            ))}
          </Mails>
          <LinksContainer>
            {(links as Strapi_ComponentMoleculesSocialLink[]).map((link) => (
              <IconLink
                key={link?.id}
                href={link?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {renderIcon(link)}
              </IconLink>
            ))}
          </LinksContainer>
        </ContactContainer>
      </Appear>
    </Container>
  );
}

export default Contact;
